<!-- 招生系统基础配置 -- 学校目录管理 -- 列表 -->
<template>
  <div>
    <list-template
      :search-config="searchConfig"
      :table-config="tableConfig"
      :table-data="tableData"
      :total="total"
      :loading="loading"
      :current-page="page"
      @onAdd="handleAdd"
      @onHandle="handleClick"
      @onExportData="handDownload"
      @onBatchImport="handDownload"
      @onSearch="onSearch"
      @onReset="onReset"
      @onChangePage="handleCurrentChange"
      @onChange="onChange"
    >
    </list-template>

    <!-- 历史记录 -->
    <h-dialog :historyDialogShow="historyDialogShow" :historyList="historyList" @close="historyDialogShow = false"></h-dialog>
    <Dialog :visible.sync="visible" showLine title="批量导入" size="small" :showFooter="false" :closeOnClickModal="false">
      <div class="t-c mb-4">先下载模板，然后按照系统的格式填写数据，最后再上传</div>
      <div class="flex justify-center">
        <el-button icon="el-icon-download" size="small" class="mr-2" type="primary" @click="handleDownFile">下载模板</el-button>
        <el-button icon="el-icon-upload2" size="small" @click="handleSelectFile">上传文件</el-button>
      </div>
    </Dialog>
  </div>
</template>

<script>
import Dialog from '@/components/common/Dialog'
import { tableListMixin, getAreaDataMixin } from '@/utils/mixins'
// import { handleChooseFile } from '@/utils'
// import http, { fileDownload } from '@/api'
export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  mixins: [tableListMixin, getAreaDataMixin],
  components: { Dialog },
  data() {
    return {
      visible: false,
      searchConfig:[
        { prop: 'name', placeholder: '搜索学校名称' },
        { prop: 'channel', placeholder: '搜索负责渠道专员' },
        { prop: 'area', placeholder: '筛选地区', tag: 'cascader', options: [] },
      ],
      tableConfig:[
        {prop:"name", label:"学校正式名称"},
        {prop:"province", label:"地区",render(row) { return row.province + row.city + row.area}},
        {prop:"nature", label:"办学类型"},
        {prop:"level", label:"学段"},
        {prop:"channel", label:"负责渠道专员"},
        {prop:"creator", label:"创建人"},
        {
          prop:"handle",
          label:"操作",
          width: "180rem",
          handle:true,
        },
      ],

      historyDialogShow: false,
      historyList: []
    }
  },
  created() {
    this.getAreaData('1,2', this.getConfig)
  },
  methods: {
    getConfig(list) {
      this.searchConfig[2].options = list
    },
    onBeforeSearch(val) {
      const { area, ...rest } = val
      const data = { ...rest }
      if (area && area.length) {
        data.city = area[1]
      }
      this.search = { ...data }
    },
    getData() {
      this.getDataApi('/zhaosheng/api/recruit/school')
    },
    handleAdd() {
      this.$router.push('./add')
    },
    handleClick(row, text) {
      if (text.type === 'edit') {
        this.$router.push('./edit?id=' + row.id)
      }

      if (text.type === 'viewHistory') {
        let params = {
          tbl_name: 'graduation_school',
          tbl_id: row.id
        }
        this.$_register.get('/api/recruit/common/get-opt-history',{params}).then(res => {
          let data = res.data.data
          this.historyList = data.list.length ? data.list : []
          this.historyDialogShow = true;
        })
      }
    },
    handDownload() {
      // this.visible = true
    },
    handleDownFile() {
      // fileDownload(`${process.env.VUE_APP_URL_OSS}preview/xxx.xlsx`, {}, { name: `xxx`, type: 'xlsx' }).then(() => this.visible = false)
    },
    handleSelectFile() {
      // handleChooseFile({ api: '/api2/api/xxx/xxx', show: true, onSuccess: () => {
      //     this.$message.success('上传成功')
      //     this.visible = false
      //   }
      // })
    }
  },
}
</script>

<style lang="scss" scoped></style>
